import React from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@material-ui/core';

import { fetchPhoton } from './network';

/**
 * Autocomplete in cui gli indirizzi vengono recuperati dal servizio free Photon 
 * 20240314 sostituzione servizi Google
 */
export default class AutocompleteIndirizzoPhoton extends React.Component{

    constructor(props){
        super(props); 
        this.state = {
            options : [],
            indirizzo : null
        }       
    } 

    fetchAddress(address) {
        let comune = this.props.comune;
        if(!comune || comune == null) {
            return;
        }
        fetchPhoton(address + '%20' + comune.nome + '%20' + comune.prov)
            .then(res => {
                let l = [];
                let i = 0;
                res
                .features
                .filter(f => f.properties.type == 'street')
                .forEach(f => {
                    let x = {
                        id : i,
                        label : f.properties.name,
                        lng : f.geometry.coordinates[0],
                        lat : f.geometry.coordinates[1]
                    }
                    if(l.filter(e => e.label == x.label).length == 0) {
                        l.push(x);
                        i++;
                    }
                });
                this.setState({
                    options : l
                })
            })
    }

    render(){
        return(            
            <Autocomplete
                disabled={this.props.comune == null}
                disablePortal
                options={this.state.options}
                style={{marginTop : 25, width : '70%'}}                                
                loadingText = 'In caricamento'
                noOptionsText = 'Nessun dato trovato'
                value = {this.state.indirizzo}
                onChange = {(event, value) => {                    
                    if(value != null) {
                        this.setState({
                            indirizzo : value
                        })  
                        this
                            .props
                            .that
                            .props
                            .setIndirizzo(
                                value.label + ', ' + this.props.comune.nome + ', ' + this.props.comune.provSigla + ', Italia', 
                                {
                                    lat : value.lat,
                                    lng : value.lng
                                }
                            ) 
                    }             
                }}
                onInputChange = {(event, value) => {  
                    if(value && value != null && value != '') {
                        this.fetchAddress(value)             
                    }               
                }}
                renderInput = {(params) => 
                    <TextField
                        {...params}
                        fullWidth
                        required
                        style = {{marginTop : 10}}                        
                        variant = 'standard'
                        placeholder = {this.props.placeholder}
                    />
                }
            />
        )
    }

}