import React from 'react';
import TopBar from '../../Utils/TopBar';
import LoadingScreen from '../../Utils/LoadingScreen';
import ErrorView from '../../Utils/ErrorView';
import { formatDate, formatDateTime, getDuration, timestampToDate } from '../../Utils/utility';
import { minTransferTime , otpMaxFilteredResults , otpMaxTravelDuration , otpMaxNumberOfResults, maxWalkDistance } from '../../resources/parameters.json';
import { fetchFromOTP } from '../../Utils/network';
import { List, ListItem, ListItemIcon, ListItemText, Button, Paper, Breadcrumbs,Typography , Divider } from '@material-ui/core';
import BusIcon from '@material-ui/icons/DirectionsBus';
import InfoIcon from '@material-ui/icons/Info'; 
import ElencoItinerariImg from '../../Images/ElencoItinerariImg.jpg';
import Footer from '../../Utils/Footer';
import CookieComponent from '../../Utils/CookieComponent';
import ErrorIcon from '@material-ui/icons/Warning';
import { Tooltip } from '@mui/material';


export default class ElencoItinerari extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
          isLoading: true,
          dataSource:null,
          partenza: this.props.location.state.Partenza,
          arrivo:this.props.location.state.Arrivo,
          dataPartenza:formatDate(new Date(this.props.location.state.DataPartenza)),
          oraPartenza:formatDateTime(new Date(this.props.location.state.OraPartenza)),
          filteredList: [],
          error: false,
        }
      }

      componentDidMount(){
        this.loadItineraries()
      }

      loadItineraries() {
        this.setState({isLoading : true});
        let departureDate = this.state.dataPartenza.date;
        let departureTime = this.state.oraPartenza.time;
        let data=new Date()
        //FIX BUG TIMEZONE 1/9/2021
        //data.setHours(departureTime.split(":")[0] - 1)
        data.setHours(departureTime.split(":")[0])
        data.setMinutes(departureTime.split(":")[1]) 
        //departureTime=data.getHours()+":"+data.getMinutes()
        //this.state.oraPartenza.time=departureTime
        let url = "plan?fromPlace=" + this.state.partenza.name + "::" + this.state.partenza.lat + "," + this.state.partenza.lon + "&toPlace=" + this.state.arrivo.name + "::" + this.state.arrivo.lat + "," + this.state.arrivo.lon + "&time=" + departureTime + "&date=" + departureDate + "&mode=TRANSIT,WALK&maxWalkDistance=" + maxWalkDistance + "&arriveBy=false&locale=it_IT&numItineraries=" + otpMaxNumberOfResults + "&minTransferTime=" + {minTransferTime}.minTransferTime+"&disableRemainingWeightHeuristic=true";
        return fetchFromOTP(url)
          .then((responseJson) => {
            let itineraries = responseJson.error?[]:responseJson.plan.itineraries

                let enabledItineraries = []                 
                        for (let i in itineraries){
                            let tempItinerary = itineraries[i];
                            if(tempItinerary.duration <= otpMaxTravelDuration && enabledItineraries.length < otpMaxFilteredResults)
                            enabledItineraries.push(tempItinerary);  
                        } 
                if(!responseJson.error){
                    //FIX BUG TIMEZONE 1/9/2021
                    if(enabledItineraries != null && enabledItineraries !== []) {
                        enabledItineraries
                            .forEach(itinerario => {
                                let unora = 0// 60*60*1000;
                                itinerario.startTime = itinerario.startTime - unora;
                                itinerario.endTime = itinerario.endTime - unora;
                                itinerario
                                    .legs
                                    .forEach(l => {
                                        l.from.departure = l.from.departure - unora;
                                        l.to.arrival = l.to.arrival - unora;
                                    });
                            });
                    }                    
                    responseJson.plan.itineraries = enabledItineraries                    
                }        
                          
            this.setState({
              isLoading: false,
              dataSource: responseJson,
              error: responseJson.error != undefined,             
              errorMessage: responseJson.error && responseJson.error.id == '409' ? 'Punti di partenza e arrivo troppo vicini o coincidenti' : ''             
            })
          })
          .catch((error) =>{
            console.error(error);
          });
      }

    render(){
        if(this.state.isLoading){
            return(
                <div>
                    <LoadingScreen/>
                </div>
            )
        }
        else if(this.state.error) {
            return(
                <div>
                    <TopBar history={this.props.history} />
                    <br></br>
                    <br></br>
                    <br></br>
                    <div style = {{display: 'flex',  justifyContent:'center', alignItems:'center' }}>                                
                        <ErrorIcon style={{width:150,height:150}}/>
                    </div>
                    <div style = {{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                        <div><h1 style={{alignText:'center'}}>Nessun itinerario trovato!</h1></div>
                    </div>
                </div>
            )
          }
        else{
        return(
            <div>
                <TopBar history={this.props.history} />
                <div style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '20vh', width: '100vw', backgroundImage: "url(" + ElencoItinerariImg + ")", minHeight: 340 }}></div>
                <br></br>
                <Paper elevation={0} >
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Button onClick={()=>{this.props.history.push('/')}}>
                            Home Page
                        </Button>
                        <Button onClick={()=>{this.props.history.push('/PianificaViaggio/PianificaViaggio')}}>
                            Pianifica Viaggio
                        </Button>
                        <Typography color="grey">ELENCO ITINERARI</Typography>
                        </Breadcrumbs>
                    </Paper>
                <div style={{fontSize:18,margin:5}}>
                   <h3 style={{marginLeft:'2%'}}> Partenza da: <span style={{color:'green',fontStyle:'italic'}}>{this.state.partenza.name}</span>
                   <br></br> 
                    Arrivo a: <span style={{color:'green',fontStyle:'italic'}}>{this.state.arrivo.name}</span> 
                    <br></br>  
                    In data : {this.state.dataPartenza.date.split("-")[2]+"-"+this.state.dataPartenza.date.split("-")[1]+"-"+this.state.dataPartenza.date.split("-")[0]}
                    <br></br> 
                    Alle ore : {("0"+this.state.oraPartenza.time.split(":")[0]).slice(-2)+":"+("0"+this.state.oraPartenza.time.split(":")[1]).slice(-2)}</h3> 
                </div>
                <Divider/>
                 { this.state.dataSource.plan.itineraries != null && this.state.dataSource.plan.itineraries!==[] ? (
                    <List>
                        {this.state.dataSource.plan.itineraries.map(
                            (itinerario,key) => {                
                            return (
                                <ListItem
                                    button          
                                    onClick={(event) => {                                       
                                        this.props.history.push('/PianificaViaggio/ItinerarioDettaglio', {legs : itinerario.legs , Partenza : this.state.partenza , Arrivo : this.state.arrivo , DataPartenza : this.state.dataPartenza, OraPartenza : this.state.oraPartenza})   
                                            }
                                        }
                                    style={{margin:15,width:'99vw'}}    
                                    >  
                                    <ListItemIcon> 
                                        <BusIcon />
                                    </ListItemIcon>
                                    {
                                        itinerario
                                            .legs
                                            .filter(l => l.mode === 'BUS')
                                            .length > 1 ?
                                                <ListItemIcon>
                                                    <Tooltip
                                                        title = 'Sono presenti trasbordi'
                                                    >
                                                        <InfoIcon />
                                                    </Tooltip> 
                                                </ListItemIcon>                                                
                                            : null
                                    }                                                                        
                                    <ListItemText primary = { key + 1 + " ) " + "Partenza: " + timestampToDate(itinerario.startTime) + "- Arrivo: " + timestampToDate(itinerario.endTime) + "- Durata: " + getDuration(itinerario.duration) } />                                                   
                                </ListItem>        
                                )             
                            })                            
                        }
                    </List>) : 
                <div>NESSUN ITINERARIO TROVATO!</div>}
                <CookieComponent/>
                <Footer/>  
            </div> 
        )
      }
    }
}