import {urlBase,urlOTP,urlNews,urlBaseVecchioServer,checkVsOnOldServer, urlPhoton} from '../resources/parameters.json';
import fetchJsonp from 'fetch-jsonp';
import "es6-promise/auto";


export const fetchData = async(path) => {        
        return fetch({urlBase}.urlBase + path)
          .then((response) =>            
            response != undefined && response.headers.get("content-type") ? response.json() : '{}'
          )
          .then((responseJson) => {                                     
            return responseJson;
          })
          .catch((error) => {
            
            return {error:error}
          });      
}

export const fetchFromOTP= async(path) => {        
  return fetch({urlOTP}.urlOTP + path)
    .then((response) =>            
      response.json()
    )
    .then((responseJson) => {                                     
      return responseJson;
    })
    .catch((error) => {
      return {error:error}   });      
}


// export const fetchNews = async(path) => {
//   require('es6-promise').polyfill(); 
//   var jsontemp={};       
//   return fetchJsonp({urlNews}.urlNews + path)
//     .then((response) =>            
//       response != undefined ? response.json() : '{}'
//     )
//     .then((responseJson) => {    
//       jsontemp['result']=responseJson;                               
//       return jsontemp;
//     })
//     .catch((error) => {
      
//       return {error:error}
//     });      
// }


export const fetchNews = async(path) => {  
  var jsontemp={};      
  return fetch({urlNews}.urlNews + path)
    .then((response) =>            
      response != undefined && response.headers.get("content-type") ? response.json() : '{}'
    )
    .then((responseJson) => {                                     
      return responseJson;
    })
    .then((responseJson) => {    
      jsontemp['result']=responseJson;                               
      return jsontemp;
     })
    .catch((error) => {
      
      return {error:error}
    });      
}


export const fetchWay = async(coords) => {
  require('es6-promise').polyfill(); 
  var jsontemp={};
  var coordsString = "";
  coords.forEach((e,i) => {
    coordsString+= e.lng+","+e.lat;
    if(i != coords.length-1) coordsString+=";";
  });
  var url = "https://routing.openstreetmap.de/routed-car/route/v1/driving/"+coordsString+"?overview=full&geometries=geojson&alternatives=false&steps=false&continue_straight=true"   
  return fetch({url}.url)
  
    .then((response) =>     
      response != undefined ? response.json() : '{}'
    )
    .then((responseJson) => {    
      jsontemp['result']=responseJson;                               
      return jsontemp;
    })
    .catch((error) => {
      
      return {error:error}
    });      
}




export const fetchFromOldService = async(path,vehicleStatus) => {
  //debugger;
  if (checkVsOnOldServer && (vehicleStatus == undefined || vehicleStatus == null || vehicleStatus.length == 0)){        
  return fetch({urlBaseVecchioServer}.urlBaseVecchioServer + path)
    .then((response) =>           
      response != undefined ? response.json() : '{}'
    
    )
    .then((responseJson) => {                                   
      return responseJson;
    })
    .catch((error) => {
      
      return {error:error}
    });
  }
  else return vehicleStatus;     
}

export const fetchPhoton = async(path) => {        
  return fetch(urlPhoton + path)
    .then((response) =>            
      response != undefined && response.headers.get("content-type") ? response.json() : '{}'
    )
    .then((responseJson) => {                                     
      return responseJson;
    })
    .catch((error) => {
      
      return {error:error}
    });      
}

 
