import React from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@material-ui/core';

import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import { getComuniByProvincia, getProvinciaEsteso } from './utility';

import { useGoogleServices } from '../resources/parameters.json'
import { fetchPhoton } from './network';

export default class AutocompleteComuni extends React.Component{

    constructor(props){
        super(props); 
        this.state = {
            comune : null
        }       
    } 

    setComune(latLng, c) {
        let comune = {
            nome : c,
            latitudine : latLng.lat,
            longitudine : latLng.lng,
            prov : getProvinciaEsteso(this.props.provincia),
            provSigla : this.props.provincia
        }
        this.props.that.setState({
            comune : comune,
            indirizzo : ''
        })                            
        if(this.props.onSelect && this.props.onSelect != null) {
            this.props.onSelect(comune);
        }
    }
    
    getCoordinatesComune(c) {
        //valorizzo latitudine e longitudine del comune selezionato
        if(useGoogleServices == 'true') {
            geocodeByAddress(c + ', ' + this.props.provincia)                        
            .then(results => {                                
                return getLatLng(results[0])
            })                                                
            .then(latLng => {                            
                this.setComune(latLng, c);
            })        
        } else {
            let p = getProvinciaEsteso(this.props.provincia);
            fetchPhoton(c + ', ' + p)
            .then(results => {
                //recupero le coordinate dall'elemento che ha 
                //name = comune
                //county = provincia
                //type = 'city'
                let l =
                    results
                    .features
                    .filter(f => 
                        f.properties.type == 'city' &&
                        f.properties.name.toUpperCase() == c.toUpperCase() &&
                        f.properties.county.toUpperCase() == p.toUpperCase()
                    );
                this.setComune({
                    lng : l[0].geometry.coordinates[0],
                    lat : l[0].geometry.coordinates[1]
                }, c);
            })
        }
    }

    render(){
        return(            
            <Autocomplete
                disabled={this.props.provincia == null}
                disablePortal
                style={{marginTop : 25, width : '70%'}}                                
                options = {this.props.elencoComuni}
                loadingText = 'In caricamento'
                noOptionsText = 'Nessun dato trovato'
                value = {this.state.comune}
                onChange = {(event, value) => {                    
                    if(value == null) return;
                    this.setState({comune : value})
                    //cerco il comune nell'elenco
                    let c = getComuniByProvincia(this.props.that.state.provincia)[value.id];                                                           
                    setTimeout(() => {                       
                        //valorizzo latitudine e longitudine del comune selezionato                                               
                        this.getCoordinatesComune(c);                
                    }, 100)                    
                }}
                renderInput = {(params) => 
                    <TextField
                        {...params}
                        fullWidth
                        required
                        style = {{marginTop : 10}}                        
                        variant = 'standard'
                        placeholder = {this.props.placeholder}
                    />
                }
            />
        )
    }

}