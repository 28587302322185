import React from 'react';
import MenuPortale from './MenuPortale';
import Manutenzione from './Manutenzione';
import { BrowserRouter, Route } from 'react-router-dom';
import OperatoriElenco from './Components/Operatori/OperatoriElenco';
import LineeElenco from './Components/Linee/LineeElenco';
import FermateElenco from './Components/Fermate/FermateElenco';
import FermateDettaglio from './Components/Fermate/FermateDettaglio';
import OperatoriDettaglio from './Components/Operatori/OperatoriDettaglio';
import ListaNotizie from './Components/Notizie/ListaNotizie';
import FermateLineaAndataDettaglio from './Components/Fermate/FermateLineaAndataDettaglio';
import FermateLineaRitornoDettaglio from './Components/Fermate/FermateLineaRitornoDettaglio';
import CorseDettaglioAndata from './Components/Fermate/Corse/CorseDettaglioAndata';
import CorseDettaglioRitorno from './Components/Fermate/Corse/CorseDettaglioRitorno';
import PianificaViaggio2 from './Components/Pianifica Viaggio/PianificaViaggio2';
import PianificaViaggio from './Components/Pianifica Viaggio/PianificaViaggio';
import ElencoItinerari from './Components/Pianifica Viaggio/ElencoItinerari';
import ItinerarioDettaglio2 from './Components/Pianifica Viaggio/ItinerarioDettaglio2';
import LineeAndataDettaglio from './Components/Linee/LineeAndataDettaglio';
import LineeRitornoDettaglio from './Components/Linee/LineeRitornoDettaglio';
import CorseDettaglioLA from './Components/Linee/Corse/CorseDettaglioLA';
import CorseDettaglioLR from './Components/Linee/Corse/CorseDettaglioLR';
import PianificaViaggioCorsa from './Components/Pianifica Viaggio/Corse/PianificaViaggioCorsa';
import FermateVicine2 from './Components/Fermate/FermateVicine2';
import FermateRicerca2 from './Components/Fermate/FermateRicerca2';
import Informazioni from './Components/Informazioni/Informazioni';
import VeicoliInMovimento2 from './Components/Linee/VeicoliInMovimento2';
import HtmlContenitor from './Components/HtmlContenitor/index';
import {useExternalServices} from './resources/parameters.json'


export default class App extends React.Component {

  render() {
    return (
      <div>
        <BrowserRouter>		                 
          <Route exact path = "/" component = {window.configurations.maintenanceMode.active?Manutenzione:MenuPortale}/>							  
          <Route exact path = "/Operatori/OperatoriElenco" component = {OperatoriElenco}/>
          <Route exact path = "/Operatori/OperatoriDettaglio" component = {OperatoriDettaglio}/>	
          <Route exact path = "/Linee/LineeElenco" component = {LineeElenco}/>
          <Route exact path = "/Linee/LineeAndataDettaglio" component = {LineeAndataDettaglio}/>
          <Route exact path = "/Linee/LineeRitornoDettaglio" component = {LineeRitornoDettaglio}/>
          <Route exact path = "/Corse/CorseDettaglioLA" component = {CorseDettaglioLA}/>
          <Route exact path = "/Corse/CorseDettaglioLR" component = {CorseDettaglioLR}/>
          <Route exact path = "/Corse/VeicoliInMovimento" component = {VeicoliInMovimento2}/>		
          <Route exact path = "/Fermate/FermateElenco" component = {FermateElenco}/>
          <Route exact path = "/Fermate/FermateVicine" component = {FermateVicine2}/>
          <Route exact path = "/Fermate/FermateRicerca" component = {FermateRicerca2}/>
          <Route exact path = "/Fermate/FermateDettaglio" component = {FermateDettaglio}/>
          <Route exact path = "/Fermate/FermateLineaAndataDettaglio" component = {FermateLineaAndataDettaglio}/>						            						            	
          <Route exact path = "/Fermate/FermateLineaRitornoDettaglio" component = {FermateLineaRitornoDettaglio}/>
          <Route exact path = "/Corse/CorseDettaglioAndata" component = {CorseDettaglioAndata}/>
          <Route exact path = "/Corse/CorseDettaglioRitorno" component = {CorseDettaglioRitorno}/>	
          <Route 
            exact 
            path = "/PianificaViaggio/PianificaViaggio" 
            component = {
              //verifico se usare i servizi esterni o recuperare gli indirizzi dalle fermate nel db
              useExternalServices == "true" ?
                PianificaViaggio : 
                PianificaViaggio2
            }
          />		
          <Route exact path = "/PianificaViaggio/ElencoItinerari" component = {ElencoItinerari}/>	
          <Route exact path = "/PianificaViaggio/ItinerarioDettaglio" component = {ItinerarioDettaglio2}/>
          <Route exact path = "/Corse/PianificaViaggioCorsa" component = {PianificaViaggioCorsa}/>			            						            						            						            	
          <Route exact path = "/Notizie/ListaNews" component = {ListaNotizie}/>
          <Route exact path = "/Informazioni/Informazioni" component = {Informazioni}/>						            						            
          <Route exact path = "/Pagine/:page" component = {HtmlContenitor}/>						            						            
			  </BrowserRouter>
      </div>
    );
  }
}


