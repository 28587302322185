import React from 'react';

import { TextField, Button } from '@material-ui/core';

import PlacesAutocomplete from 'react-places-autocomplete';
import { IconButton, Tooltip } from '@mui/material';
import {
    geocodeByPlaceId,
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import AutocompleteComuni from './AutocompleteComuni';
import SelectProvince from './SelectProvince';
import AutocompleteIndirizzoPhoton from './AutocompleteIndirizzoPhoton';

import { calcCrow } from './utility';

import MapIcon from '@material-ui/icons/Map';

import { useGoogleServices } from '../resources/parameters.json';

const google = window.google

export default class ProvinciaComuneIndirizzoAC extends React.Component {

    constructor(props){
        super(props); 
        this.state = {
            provincia : null,
            elencoComuni : [],
            comune : null,
            indirizzo : '',
            distanze : new Map(),
            //statoBtn : this.props.mostraBtn ? 'POSIZIONE' : 'TESTUALE',
            //labelBtn : 'SELEZIONE TESTUALE'          
            statoBtn : 'TESTUALE',
            labelBtn : 'SELEZIONE SU MAPPA'          
        }       
    }

    componentDidMount() {
        //this.caricaPosizione();
		console.log("useGoogleServices: " + useGoogleServices)
    }

    onPlacesChange(address) {
        this.setState({ indirizzo: address });
    };

    onSelect(address) {
        this.setState({ indirizzo: address })
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
               this.props.setIndirizzo(address, latLng)                
           }) 
          .catch(error => console.error('Error', error));
    };

    cambiaStatoBtn() {
        /*
        if(this.state.statoBtn === 'POSIZIONE') {
            this.setState({
                statoBtn : 'TESTUALE',
                labelBtn : 'SELEZIONE SU MAPPA',
                indirizzo : null
            });            
        } else if(this.state.statoBtn === 'TESTUALE') {
            this.setState({
                statoBtn : 'MAPPA',
                labelBtn : 'USA LA MIA POSIZIONE',
                indirizzo : null
            });
        } else if(this.state.statoBtn === 'MAPPA') {
            this.caricaPosizione();
            this.setState({
                statoBtn : 'POSIZIONE',
                labelBtn : 'SELEZIONE TESTUALE'
            });
        }*/
        if(this.state.statoBtn === 'TESTUALE') {
            this.setState({
                statoBtn : 'MAPPA',
                labelBtn : 'SELEZIONE TESTUALE',
                indirizzo : null
            });
        } else if(this.state.statoBtn === 'MAPPA') {
            this.setState({
                statoBtn : 'TESTUALE',
                labelBtn : 'SELEZIONE SU MAPPA',
                indirizzo : null
            });
        }
    }

    caricaPosizione() {
        if(!this.props.mostraBtn) {
            return;
        }
        this.setState({isLoading : true});
        //Promise che si occupa di ottenere la posizione corrente dal gps integrato nel dispositivo
        new Promise(function(resolve, reject) {    
          navigator.geolocation.getCurrentPosition(
            pos => { 
                            
              resolve(pos);                      
            },
            error => { resolve({message : 'error'}) },
            { enableHighAccuracy: false, timeout: 20000}
          );
        }).then(
          
          (pos) => {               
            if(pos.error != undefined){
              this.setState({
                isLoading:false,
                error:true,
              })
              return;
            }
            if(pos.coords) {
                let indirizzo = 'La mia posizione (' + pos.coords.latitude + ', ' + pos.coords.longitude + ')'
                this.setState({
                    isLoading : false,
                    error : false,
                    indirizzo : indirizzo
                });            
                this.props.setIndirizzo(indirizzo, {
                    lat : pos.coords.latitude,
                    lng : pos.coords.longitude
                })                
            }            
          }
        );
      }

    render(){
        //filtro gli indirizzi in modo che vengano mostrati solo quelli con province calabresi
        const filterLocalities = (prediction) => prediction.description.match(/^.*([c][z][,]|[k][r][,]|[c][s][,]|[v][v][,]|[r][c][,]).*$/gi)
        
        return(            
            <div>
                {
                    this.props.title && this.props.title != null ?
                    <p>
                        {this.props.title} :
                    </p>
                    : null
                }                
                {
                    this.state.statoBtn === 'TESTUALE' ?
                        <div>
                            <SelectProvince
                                that = {this}
                                placeholder = {'Selezionare la provincia'}
                            />                        
                            <AutocompleteComuni
                                that = {this}
                                provincia = {this.state.provincia}
                                elencoComuni = {this.state.elencoComuni}
                                placeholder = {'Selezionare il comune'}
                                onSelect = {this.props.onSelectComune}
                            />   
                            {
                                this.props.mostraBtn ? 
                                        //verifico sulla property se usare i servizi Google
                                        useGoogleServices == "true" ?
                                            <PlacesAutocomplete                      
                                                value={this.state.indirizzo}                      
                                                style={{ display: 'inline-block'}}
                                                filterPredictions={filterLocalities}
                                                highlightFirstSuggestion={false}                         
                                                onSelect={(address) =>
                                                    this.onSelect(address)
                                                }                      
                                                onChange={(address) =>
                                                    this.onPlacesChange(address)
                                                }
                                                searchOptions={{
                                                    location: new google.maps.LatLng(
                                                        this.state.comune != null && this.state.comune.latitudine != null ? this.state.comune.latitudine : 39.184691, 
                                                        this.state.comune != null && this.state.comune.longitudine != null ? this.state.comune.longitudine : 16.514506
                                                    ),
                                                    radius: 1000,
                                                    componentRestrictions: {
                                                        country: ['it'],
                                                    }                                    
                                                }}                                               
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div style={{marginTop : 20, width : '100%'}}>
                                                    <TextField                            
                                                        margin={'dense'}                            
                                                        {...getInputProps({
                                                            placeholder: 'Inserisci indirizzo',
                                                            className: 'location-search-input',
                                                            label: "",
                                                            shrink: true,
                                                            
                                                        })}                                            
                                                        style={{marginRight : 20, width : '68%'}}                                                                        
                                                    />  
                                                    {
                                                        this.props.mostraBtn ?
                                                            <Button style={{ backgroundColor:'#31aa47', width : '25%', fontSize : 13 }} 
                                                                variant="contained" color="secondary"
                                                                onClick={() => {                                        
                                                                        this.cambiaStatoBtn();
                                                                    }
                                                                }
                                                            >
                                                                    {this.state.labelBtn}
                                                            </Button>                                              
                                                            : null
                                                    }                                    
                                                    <div className="autocomplete-dropdown-container">

                                                    {loading && <div>Caricamento...</div>}
                                                    {
                                                        suggestions                      
                                                        .filter(s => 
                                                            s
                                                                .terms
                                                                .filter(t => 
                                                                    t.value.toUpperCase() === this.state.provincia.toUpperCase() ||
                                                                    t.value.toUpperCase() === this.state.comune.nome.toUpperCase()
                                                                ).length == 2                                                
                                                        )                                                                                       
                                                        .filter(filteredSuggestion => {                                                  
                                                            let placeID = filteredSuggestion.placeId;
                                                            let d = this.state.distanze.get(placeID);
                                                            if(!d) {                                        
                                                                geocodeByPlaceId(filteredSuggestion.placeId)                                   
                                                                .then(res => getLatLng(res[0]))
                                                                .then(indirizzoLatLng => {                                             
                                                                    d = calcCrow(
                                                                        this.state.comune.latitudine,
                                                                        this.state.comune.longitudine,
                                                                        indirizzoLatLng.lat,
                                                                        indirizzoLatLng.lng
                                                                    );
                                                                    this.state.distanze.set(placeID, d);
                                                                });                                                                                
                                                            }                                                                                                            
                                                            return d && d < 10;
                                                        })                                             
                                                        .map(filteredSuggestion => {
                                                        const className = filteredSuggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = filteredSuggestion.active
                                                            ? { backgroundColor: '#afafaf', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };                                
                                                        return (
                                                            <div
                                                            {...getSuggestionItemProps(filteredSuggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                            >
                                                            <br></br>
                                                            <span
                                                                style={{ marginLeft: window.innerWidth > 500 ?'35%':50 }}
                                                                onClick={() => {
                                                                this.onSelect(filteredSuggestion.description)
                                                                }}>{filteredSuggestion.description}</span>
                                                            <br></br>
                                                            <br></br>
                                                            </div>
                                                        );
                                                        })}
                                                    </div>

                                                </div>
                                                )}
                                            </PlacesAutocomplete>  
                                            :
                                            //servizi free
                                            <AutocompleteIndirizzoPhoton
                                                that = {this}
                                                comune = {this.state.comune}
                                                placeholder = {'Inserisci indirizzo'}
                                                onSelect = {this.onSelect}
                                            />   
                                : null
                            }                         
                        </div>          
                    : 
                    <div>
                        <TextField                            
                            margin={'dense'}                                                        
                            disabled={true}
                            style={{marginRight : 20, width : this.state.statoBtn === 'MAPPA' ? '63%' : '68%'}}   
                            value=
                                {                                    
                                    this.props.that.state.partenza && 
                                    this.props.that.state.partenza.name &&
                                    this.props.that.state.partenza.name.startsWith("Punto specificato ")
                                    ? this.props.that.state.partenza.name 
                                    : this.state.indirizzo                        
                                }                         
                        />  
                        {
                            this.state.statoBtn === 'MAPPA' ?
                                <Tooltip
                                    title = 'Apri la mappa'
                                >
                                    <IconButton 
                                        color="primary"
                                        onClick={this.props.azioneMappa}
                                    >
                                        <MapIcon />
                                    </IconButton>               
                                </Tooltip>
                                : null
                        }                        
                        <Button style={{ backgroundColor:'#31aa47', width : '25%', fontSize : 13 }} 
                            variant="contained" color="secondary"
                            onClick={() => {                            
                                    this.cambiaStatoBtn();
                                }
                            }
                        >
                                {this.state.labelBtn}
                        </Button>                                                   
                    </div>

                }                   
            </div>
        )
    }
}